@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700;800&display=swap');

:root {
  --main-color: #3d6eaa;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  direction: rtl;
}

/* // to delete */
img {
  height: 100px;
  padding: 5px;
}

/* ** */

header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 75px;
  background-color: #fff;
  z-index: 1;
}

.pt_5 {
  padding-top: 5px;
}

/* header,
.mobile_header {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
} */

h1 {
  font-size: 30px;
  text-align: center;
}

header img {
  height: 80px;
}

header a {
  text-decoration: none;
  font-weight: 700;
  color: black;
  margin: 0px 10px;
  font-size: 20px;
}

.btn_navbar a {
  border: none;
  color: white;
  background-color: var(--main-color);
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 7px 14px;
  padding-top: 4px;
  font-size: 17px;
}

.welcome_section {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #e5e1d5; */
  background-image: url('./Assets/bg_white.png');
  padding: 80px 20px;
  text-align: center;
}

.sub_welcome {
  width: 500px;
}

.welcome_section img {
  height: 250px;
  width: 500px;
  border-radius: 20px;
}

.sub_welcome h1 {
  font-size: 45px;
  color: var(--main-color);
}

.sub_welcome p {
  font-size: 20px;
  font-weight: bold;
}

.sub_welcome img {
  height: 90px;
  width: 390px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  margin: 10px;
}

.sub_welcome a {
  color: white;
  /* border: 2px solid white; */
  background-color: var(--main-color);
  text-decoration: none;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 8px 16px;
  padding-top: 5px;
  font-size: 18px;
  margin: 10px;
}

.welcome_section video {
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: #fff; */
}

.testimonials_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px;
  color: white;
  background-color: #3d6eaa;
  /* background-image: url('./Assets/bg_donations.png'); */
}

.sub_test {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}

.testimonials {
  margin: 10px;
  width: 30vw;
  text-align: center;
  border-radius: 20px;
}

.testimonials img {
  height: auto;
  width: 26vw;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.testimonials_section a,
.shop_section a {
  border: 2px solid white;
  padding: 8px 16px;
  padding-top: 5px;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  color: white;
}

.donations_section {
  padding: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('./Assets/bg_white.png');
}

.sub_donations {
  /* background-color: var(--main-color); */
  /* background-image: url('./Assets/bg_donations.png'); */
  margin: 15px;
  /* margin-top: 35px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  padding: 10px;
}

.donations_options {
  background: var(--main-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: white;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 260px;
  width: 210px;
  /* border: 2px solid white; */
  margin: 10px;
}

.donations_options img {
  border-radius: 15px;
  height: 120px;
  width: 120px;
  /* margin-left: 30px; */
}

.donations_options a,
.tel_bit {
  color: white;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  padding: 7px 14px;
  padding-top: 4px;
  font-size: 17px;
  border: 2px solid white;
  margin: 0 20px;
}

.tel_bit {
  padding-top: 7px;
}

.shop_section {
  text-align: center;
  padding: 35px 0px;
  background-color: var(--main-color);
  color: white;
}

.shop_section a {
  color: white;
  border-color: white;
}

.sub_shop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 5px;
}

.articles {
  width: 20%;
  margin: 10px;
  /* border: 2px solid white; */
  height: 260px;
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* background-color: var(--main-color) */
  background-image: url('./Assets/bg_donations.png');
  background-position: fixed;
  background-size: cover;
}

.articles img {
  height: 130px;
  width: 130px;
  background-color: #fff;
  border-radius: 20px;
  padding: 5;
}

#plata_img {
  width: 130px;
}

.articles h3 {
  padding: 10px;
}

.articles a, .articles button {
  margin-bottom: 20px;
  padding: 7px 14px;
  padding-top: 4px;
  font-size: 17px;
}

.img_container {
  background-color: #fff;
  width: 100%;
  border-radius: 17px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  text-align: center;
  padding: 20px;
}

.mobile_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  background-color: white;
}

.mobile_header img:first-child {
  padding: 10px;
}

.logo_mobile {
  width: 100px;
  height: 80px;
  padding: 15px;
  border-radius: 20px;
}

.link_navbar_mobile {
  position: fixed;
  z-index: 1;
  top: 90px;
  left: 0;
  right: 0;
  /* height: 100px; */
  width: 100vw;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 20px; */
  flex-direction: column;
  /* margin-top: 10px; */
}

.mobile_div {
  padding: 7px;
}

.link_navbar_mobile a {
  text-decoration: none;
  color: white;
  font-size: 28px;
  margin: 7px;
}

/* about page */

.about {
  background-color: var(--main-color);
}

.about h1 {
  text-align: right;
  padding: 15px;
  font-size: 40px;
  color: white;
}

.about h1:first-child {
  margin-top: 80px;
}

.about p {
  padding: 10px 5vw;
  font-size: 20px;
  text-align: justify;
  color: white;
}

.about video {
  border-radius: 20px;
  margin: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.center_about {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.about_icon {
  /* background-color: #fff; */
  background-image: url('./Assets/bg_white.png');
  width: 320px;
  max-width: 80vw;
  height: auto;
  margin: 10px;
  border-radius: 20px;
}

.about_icon img {
  width: 100%;
  height: auto;
  padding: 0px;
  border-radius: 20px;
}

.about_icon p {
  color: var(--main-color);
  padding: 10px 15px;
  text-align: center;
}

.about_icon .name {
  font-size: 23px;
  font-weight: bold;
  padding-bottom: 5px;
}

.about_icon button {
  color: white;
  background-color: var(--main-color);
  margin: 10px;
  border: none;
  padding: 3px 8px 5px 8px;
  border-radius: 5px;
  font-size: 16px;
}

.about_icon .letter_img {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.letter_img button {
  position: absolute;
}

.letter_img img {
  width: 330px;
  height: auto;
  border-radius: 10px;
}

.projects h2 {
  color: white;
  margin-top: 75px;
  text-align: center;
}

.gallery {
  background-color: #3d6eaa;
  /* background-image: linear-gradient(256deg, #3d6eaa 0%, #6796cd 50%, #ffffff 100%); */
  margin-top: 25px;
}

.projects_video, .projects_photo {
  height: 350px;
  width: auto;
  margin: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.projects_photo {
  border: 2px solid var(--main-color);
  padding: 0;
}

.sub_donations {
  flex-direction: row;
}

.donations_page h1 {
  display: none;
  color: var(--main-color);
}

.donations_page h2 {
  text-align: center;
  margin-top: 80px;
  color: var(--main-color);
  padding-top: 20px;
}

.bg_main {
  /* background-color: var(--main-color); */
  background-image: url('./Assets/bg_donations.png');
  /* background-position: fixed;
  background-size: cover; */
  color: white;
}

.bg_main h2 {
  color: white;
}

.donations_choices {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 600px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 0;
}

.donations_choices img {
  border-radius: 20px;
  height: 180px;
  width: 180px;
}

.donations_subtitle {
  margin: 35px 0px -30px 0px;
}

.shop_page {
  padding-top: 80px;
  background-color: var(--main-color);
}

.shop_container h3 {
  color: white;
}

.sub_shop button {
  color: white;
  border: 2px solid white;
  background-color: transparent;
  border-radius: 10px;
}

.shop_navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  margin: 30px 20vw 0px 20vw;
  padding: 10px;
  border-radius: 20px;
}

.shop_navbar button {
  color: white;
  background-color: var(--main-color);
  padding: 5px 10px 7px 10px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
}

.shop_navbar p {
  font-size: 18px;
}

.shop_page form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #4281c8;
  margin: 20px;
  border-radius: 20px;
}

.shop_page form label {
  color: white;
  margin: 5px;
}

.shop_page form input, .shop_page form select {
  margin: 5px;
  width: 300px;
  padding: 5px;
  font-size: 16px;
}

option {
  padding: 5px;
}

.form_submit {
  padding: 30px 20vw 50vh 20vw;
  color: white;
  text-align: center;
}

.loading {
  color: white;
  text-align: center;
}

video {
  background-color: #fff;
}

@media screen and (min-width:550px) {
  header {
    display: flex;
  }

  .mobile_header {
    display: none;
  }
}

@media screen and (max-width:550px) {
  header {
    display: none;
  }

  h1 {
    font-size: 26px;
  }

  .mobile_header {
    display: flex;
    z-index: 1;
  }

  .welcome_section {
    margin-top: 90px;
    padding: 50px 20px;
  }

  .sub_welcome p {
    padding: 10px;
  }

  video {
    max-width: 90vw;
    height: auto;
    margin-bottom: 25px;
  }

  .sub_test {
    flex-wrap: wrap;
  }

  .testimonials {
    width: 80vw;
  }

  .testimonials img {
    width: 80vw;
  }

  .donations_section {
    padding: 35px 0px;
  }

  .donations_section h1, .shop_section h1 {
    padding: 0px 35px;
  }

  .sub_donations {
    margin: 15px 0px;
  }

  .donations_options {
    height: 275px;
    width: 42vw;
  }
  .about h1:first-child {
    margin-top: 100px;
  }

  .projects h2 {
    padding: 20px;
  }

  .donations_page h2 {
    margin-top: 100px;
  }

  .shop_navbar {
    margin: 30px 10vw 0px 10vw;
  }

  .form_submit {
    padding: 30px 10vw 50vh 10vw
  }

  .projects_video, .projects_photo {
    width: 40vw;
    height: auto;
    margin: 10px;
  }
}

@media all and (min-width:900px) {
  .sub_shop {
    padding-left: 15vw;
    padding-right: 15vw;
  }
}

@media all and (max-width:390px) {
  .sub_welcome img {
      height: auto;
      width: 300px;
  }
}

#NagishLiTrigger img {
  width: 30px;
  height: 30px;
}

#NagishLiTrigger {
  max-width: none;
  max-height: none;
  bottom: 15px;
  right: 15px;
}

#NagishLiTrigger .nagishli-trigger-icon {
  width: 50px;
  height: 50px;
}
